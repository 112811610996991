import React, { useState, useEffect } from "react";
import { AppContainer } from "containers/App";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import Cookies from "universal-cookie";

const App = () => {

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <AppContainer />
      </ThemeProvider>
    </CookiesProvider>
  );
};

export default App;
