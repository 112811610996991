// @ts-nocheck
import React, { Component } from "react";
import { authUser } from "utils/helper/Cognito";
import { ForgotContainer } from "../Forgot";
import { ErrorContainer } from "../Error";
import { LoginForm } from "components/LoginForm";
import { MFA } from "containers/MFA";
import { useHistory } from "react-router-dom";
import { getJWTToken, getJWTTokenValidity } from "./verifyToken";
import { setCookies } from "utils/helper/cookieHelper";
import { datadogLogs } from "@datadog/browser-logs";

const urlParams = new URLSearchParams(window.location.search);
const isUrlParams = window.location.search === "" ? true : false;

// const history = useHistory();
let cogUserEventObj: any;

class HomeContainer extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: {},
      showErrorPage: this.props ? this.props.showErrorPage : false,
      result: {},
      display: true,
      isForgotPassword: this.props ? this.props.forgotPassword : false,
      username: "",
      loading: false,
      errorCount: 0,
      spUser: false,
      showPassword: false,
      openMFAModal: false,
      modalViewIndex: -1,
      authResult: {},
      userCreds: { Username: "", Password: "" },
      resetRequired:false
    };

    this.signIn = this.signIn.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.resetError = this.resetError.bind(this);
    this.checkDomainIdentifier = this.checkDomainIdentifier.bind(this);
    this.resetLogin = this.resetLogin.bind(this);
    this.resetLogin = this.resetLogin.bind(this);
    this.triggerMFAModal = this.triggerMFAModal.bind(this);
    this.afterAuthHandle = this.afterAuthHandle.bind(this);
    this.triggerAfterMFAAuths = this.triggerAfterMFAAuths.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (urlParams.has("error_type") || urlParams.has("sso")) {
      this.setState({ showErrorPage: true, display: false });
      return;
    }
    let userToken = getJWTTokenValidity();
    if(userToken ){
      this.setState({ loading: true });
      let token = getJWTToken();
      setCookies(token);
      if (urlParams.has("username")) {
        this.setState({ username: urlParams.get("username") });
      } else if (
        urlParams.has("forgotpassword") &&
        urlParams.get("forgotpassword") === "show"
      ) {
        this.setState({ isForgotPassword: true });
      } 
      } 
  }


  resetError() {
    this.setState({ error: {} });
  }

  resetLogin() {
    this.setState({
      spUser: false,
      loading: false,
      showPassword: false,
    });
  }

  checkDomainIdentifier(userName: string) {
    let self = this;
    this.setState({ loading: true });
    const emailDomain = userName.split("@")[1];
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(userName)) {
      if (userName) {
        this.setState({
          loading: false,
          spUser: false,
          showPassword: true,
          error: {},
          errorCount: 0,
        });
      }
    } else {
      fetch(
        `${process.env.REACT_APP_GE_BASE_URL}/authorizeUrl?emailDomain=${emailDomain}`,
      )
        .then((result: any) => {
          if (result.status === 404) {
            self.setState({
              spUser: false,
              loading: false,
              showPassword: true,
            });
          } else {
            result.json().then(function (data) {
              if (result.ok) {
                window.location.href = data.url;
              }
            });
          }
        })
        .catch((error) => {
          self.setState({ error, loading: false });
          datadogLogs.logger.error('Home exception', error);
        });
    }
  }

  triggerMFAModal(modalViewIndex: number, result: any) {
    this.setState({ openMFAModal: true, modalViewIndex, authResult: result });
  }

  afterAuthHandle(err: any, result: any) {
    let self = this;
    if (err) {
      self.setState({
        error: err,
        loading: false,
        errorCount: self.state.errorCount + 1,
      });
    } else {
      self.setState({ result: result, loading: true, errorCount: 0 });
      if (result.response.isMobile) {
        localStorage.setItem("geToken", result.response.data);
        window.location.reload();
      } else if (result.response.url) {
        window.location.href = result.response.url;
      } else if (result.lsLearnerPortalUrl) {
        window.location.href = result.lsLearnerPortalUrl;
      } else {
        const errObj = {
          code: "SETCOOKIEFAILURE",
          name: "FAILEDTOSETCOOKIE",
          message: "Try again later!",
        };
        self.setState({
          error: errObj,
          loading: false,
          errorCount: self.state.errorCount + 1,
        });
      }
    }
  }

  triggerAfterMFAAuths(err: any, result: any) {
    this.afterAuthHandle(err, result);
  }

  signIn(userName: string, password: string) {
    const creds: any = { Username: userName, Password: password };
    this.setState({ userCreds: creds });
    let self = this;
    this.setState({ loading: true });
    try {
      authUser(
        userName,
        password,
        (
          err: any,
          result: any,
          triggerModal?: boolean,
          modalViewIndex?: number,
          eventObj?: any,
          ifMFAEnabledPlatform?: boolean,
        ) => {
          if (err) {
            if (err.code == 'PasswordResetRequiredException') {
              this.resetRequired = true;
              this.forgotPassword(true);
            }
            
            self.setState({
              error: err,
              loading: false,
              errorCount: self.state.errorCount + 1,
            });
          }

          if (ifMFAEnabledPlatform) {
            cogUserEventObj = eventObj;
            if (triggerModal) {
              this.triggerMFAModal(modalViewIndex, result);
            } else {
              this.setState({ loading: true });
              this.triggerMFAModal(-1, result);
              // NON MFA USER - MFA NOT ENABLED AND MFA FLAG NOT SET
            }
          } else {
            this.afterAuthHandle(err, result);
          }
        },
      );
    } catch (err) {
      self.setState({
        error: err,
        loading: false,
        errorCount: self.state.errorCount + 1,
      });
      datadogLogs.logger.error('Home exception', err);
    }
  }

  forgotPassword(isForgotPassword: boolean) {
    this.setState({
      isForgotPassword: isForgotPassword,
      showPassword: false,
      spUser: false,
    });
  }

  triggerOnClose() {
    this.setState({ openMFAModal: false });
  }

  render() {
    const { error, result, username, loading, spUser, showPassword } =
      this.state;
    return (
      <>
        {this.state.display ? (
          <div className="centerAlign">
            {!this.state.isForgotPassword ? (
              <div>
                <LoginForm
                  differentAccount={this.resetLogin}
                  spCheck={this.checkDomainIdentifier}
                  submit={this.signIn}
                  isSPUser={spUser}
                  error={!error ? null : error}
                  result={!result ? null : result}
                  username={!username ? null : username}
                  forgotPassword={this.forgotPassword}
                  loading={loading}
                  resetError={this.resetError}
                  errorCount={this.state.errorCount}
                  showPassword={showPassword}
                />
                <MFA
                  authResult={this.state.authResult}
                  cogUserEventObj={cogUserEventObj}
                  modalViewIndex={this.state.modalViewIndex}
                  openMFAModal={this.state.openMFAModal}
                  authDetails={this.state.userCreds}
                  triggerOnClose={() => {
                    this.triggerOnClose();
                  }}
                  triggerAfterMFAAuths={this.triggerAfterMFAAuths}
                ></MFA>
              </div>
            ) : (
                <ForgotContainer forgotPassword={this.forgotPassword} resetRequired={this.resetRequired} />
            )}
          </div>
        ) : (
          <ErrorContainer />
        )}
      </>
    );
  }
}

export default HomeContainer;
